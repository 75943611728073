import Buy from "@/components/Buy";
import ChefToken from "@/components/ChefToken";
import ImageFan from "@/components/ImageFan";
import MobPlusIndicator from "@/components/MobPlusIndicator";
import SaveRecipe from "@/components/SaveRecipe";
import SaveRecipeToShoppingList from "@/components/SaveRecipeToShoppingList";
import SaveStatus from "@/components/SaveStatus";
import SectionToken from "@/components/SectionToken";
import useSubscriptionPermission from "@/hooks/useSubscriptionPermission";
import cloudflareLoader from "@/lib/cloudflare-images";
import { pushEvent } from "@/lib/gtm";
import clsx from "clsx";
import Image from "next/legacy/image";
import Link from "next/link";
import { useFeatureFlagEnabled } from "posthog-js/react";

type PopoverSide = "right" | "left";

const EntryThumbnail = ({
  entry,
  aspectRatio = "square",
  disableRecipeActions = false,
  overlay = false,
  showSection = false,
  disableLink = false,
  onClick = () => {},
  showSummary = true,
  theme = "dark",
  container = false,
  showChef = false,
  priority = false,
  avoidCollisions = true,
  mobileTitleSize = "base",
  saveRecipePopoverSide = "right" as PopoverSide,
}) => {
  const flagEnabled = useFeatureFlagEnabled(
    "PMF-WEB-06_Add_Recipes_Directly_to_Shopping_List"
  );
  const { subscriptionPermission, showLockedBadge } =
    useSubscriptionPermission(entry);

  const href = `/${entry?.uri || ""}`;
  const image = entry.image?.[0];

  const handleClick = () => {
    onClick();
  };

  // If the entry is a collection, we'll have three images to show from "firstRecipes" attribute, each of which has an image property
  const images = entry.firstRecipes?.map((recipe) => recipe.image?.[0]);

  // We might have a chef associated
  const chef = entry.chefs?.[0];

  showSummary =
    showSummary &&
    !!entry.summary &&
    entry.sectionHandle !== "recipes" &&
    entry.sectionHandle !== "collections";

  const showMeta =
    (entry.sectionHandle === "recipes" && showChef) ||
    (entry.sectionHandle !== "recipes" && showSection);

  const showMobPlusIndicator =
    showLockedBadge && process.env.NEXT_PUBLIC_PAYWALL_ALL_RECIPES !== "true";

  return (
    <div className={clsx("EntryThumbnail", "group relative @container")}>
      <div
        className={clsx(
          "EntryThumbnail__base",
          "relative rounded-b-2xl rounded-t-2xl",
          {
            "bg-zinc-950 bg-opacity-[0.05] text-zinc-50":
              theme === "dark" && container,
            "bg-zinc-50 bg-opacity-[0.05] text-zinc-800":
              theme === "light" && container,
          }
        )}
      >
        {showMeta && (
          <div className={clsx("EntryThumbnail__meta", "py-2.5")}>
            {entry.sectionHandle === "recipes" && showChef && (
              <_ChefToken chef={chef} theme={theme} />
            )}
            {entry.sectionHandle !== "recipes" && showSection && (
              <_SectionToken
                sectionHandle={entry.sectionHandle}
                theme={theme}
              />
            )}
          </div>
        )}

        <div className={clsx("relative")}>
          <_Wrapper
            label={entry.title}
            href={href}
            disableLink={disableLink}
            className={clsx(
              "EntryThumbnail__imageWrapper",
              "relative isolate block overflow-hidden rounded-xl @3xs:rounded-2xl",
              {
                "bg-white/5": theme === "light" && !overlay,
                "bg-zinc-950/5": theme === "dark" && !overlay,
                "aspect-square": aspectRatio === "square",
                "aspect-16/11": aspectRatio === "landscape",
              }
            )}
            onClick={handleClick}
          >
            {(!images || images.length === 0) && !!image && (
              <Image
                src={image.url}
                layout="fill"
                objectFit="cover"
                alt={image.title}
                sizes="(max-width: 768px) 100vw, (max-width: 1540px) 240px, 390px"
                className={clsx(
                  "EntryThumbnail__image",
                  "select-none transition-transform ease-out hover:scale-[1.025]"
                )}
                draggable={false}
                priority={priority}
                loader={cloudflareLoader}
              />
            )}

            {images && images.length > 0 && <ImageFan images={images} />}

            {overlay && (
              <div
                className={clsx(
                  "absolute inset-0 bg-gradient-to-b from-transparent via-black/25 to-black/75"
                )}
              ></div>
            )}
          </_Wrapper>

          {showMobPlusIndicator && (
            <div
              className={clsx(
                "EntryThumbnail__badge",
                "absolute left-2 top-2 @3xs:left-4 @3xs:top-4"
              )}
            >
              <MobPlusIndicator
                locked={!subscriptionPermission.isMobPlusMember}
              />
            </div>
          )}

          {entry.sectionHandle === "recipes" && !disableRecipeActions && (
            <>
              <div
                className={clsx(
                  "absolute right-2 top-2 flex flex-col @3xs:right-4 @3xs:top-4 print:hidden"
                )}
              >
                <div className={clsx("EntryThumbnail__saveRecipe")}>
                  {flagEnabled ? (
                    <SaveRecipeToShoppingList recipe={entry} />
                  ) : (
                    <SaveRecipe
                      recipe={entry}
                      theme="dark"
                      size="sm"
                      avoidCollisions={avoidCollisions}
                      side={saveRecipePopoverSide}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <div
        className={clsx("EntryThumbnail__caption", " pt-2.5 ", {
          relative: !overlay,
          "absolute bottom-0 space-y-1.5 p-5": overlay,
        })}
      >
        <_Wrapper
          className={clsx("line-clamp-2 overflow-hidden @2xs:line-clamp-2", {
            "text-zinc-800": theme === "dark" && !overlay,
            "text-zinc-50": theme === "light" || overlay,
            "text-sm @2xs:text-base": mobileTitleSize === "base",
            "text-base @2xs:text-lg": mobileTitleSize === "large",
          })}
          onClick={handleClick}
          href={href}
          disableLink={disableLink}
        >
          {entry.title || (
            <>
              {/* Two lines breaks, to maintain height if this thumbnail is a placeholder */}
              <br />
              <br />
            </>
          )}
        </_Wrapper>
      </div>

      {showSummary && (
        <div
          className={clsx(
            "EntryThumbnail_summary",
            "line-clamp-4 pt-1 font-body text-sm text-zinc-500 transition ease-out group-hover:text-zinc-700"
          )}
        >
          {entry.summary}
        </div>
      )}

      {entry.sectionHandle === "recipes" && entry.menu && (
        <div
          className={clsx(
            "EntryThumbnail__saveStatus",
            "relative flex overflow-hidden whitespace-nowrap"
          )}
        >
          <SaveStatus recipe={entry} />
        </div>
      )}

      {entry.sectionHandle === "products" && (
        <div className={clsx("EntryThumbnail__buy", "pt-6")}>
          <Buy entry={entry} />
        </div>
      )}
    </div>
  );
};

const _Wrapper = ({
  children,
  href,
  onClick,
  className,
  disableLink,
  label = "",
  ...props
}) => {
  if (disableLink || !href) {
    return <div className={className}>{children}</div>;
  }

  return (
    <Link
      href={href}
      prefetch={false}
      onClick={onClick}
      className={className}
      draggable={false}
      aria-label={label}
    >
      {children}
    </Link>
  );
};

const _ChefToken = ({ chef, theme }) => {
  return (
    <div className={clsx("EntryThumbnail_chef")}>
      {!!chef && <ChefToken chef={chef} size="sm" theme={theme} />}
      {!chef && <ChefToken isMob size="sm" theme={theme} />}
    </div>
  );
};

const _SectionToken = ({ sectionHandle, theme }) => {
  return (
    <div className={clsx("EntryThumbnail_sectionToken")}>
      <SectionToken sectionHandle={sectionHandle} size="sm" theme={theme} />
    </div>
  );
};

export default EntryThumbnail;
